// 3rd party
import React, {useEffect, useContext} from "react"
import {graphql as gql} from "gatsby"

// context/lib
import {GlobalDispatchContext} from "../context/GlobalContextProvider"
import {removeDuplicates} from "../lib/utils"

// components
import Seo from "../components/general/seo"
import Container from "../components/styled/container"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import ArchiveList from "../components/archive/archiveList"
import MainProjectArchive from "../components/archive/mainProjectArchive"
import ProjectArchive from "../components/archive/projectArchive"
import Scrollable from "../components/general/scrollable"

export default function Project({data}) {
  // data
  let {project, parentWorks, childWorks} = data
  let type = "project"
  let works = !!parentWorks.edges.length ? parentWorks.edges : childWorks.edges // [...childWorks.edges.concat(parentWorks.edges)]
  works = removeDuplicates(works, "id")
  let hasChildren = !!parentWorks.edges.length //!!project.children?.nodes.length
  let mainSub = project.mainSub?.mainSub?.length && project.mainSub.mainSub[0]

  // context
  const appDispatch = useContext(GlobalDispatchContext)

  // effect
  useEffect(() => {
    let page = {
      main: {medium: 0, project: project.parent?.node.id || project.id},
      sub: {medium: 0, project: project.id},
      currentId: 0,
      template: "project"
      //type: type
    }
    appDispatch({
      type: "setPage",
      value: {
        type: type,
        page: page
      }
    })
  }, [appDispatch, type, project])

  useEffect(() => {
    appDispatch({type: "setAttached", value: []})
  }, [appDispatch])
  return (
    <>
      <Seo title={project.title} description={project.description.content} />
      <Container>
        <Breadcrumbs postType={type} mainProject={project.parent?.node || project} child={!!project.parent?.node && project} />
        <Scrollable>
          <ArchiveList hasLargeFirst={!!mainSub && !hasChildren}>
            {hasChildren ? <MainProjectArchive {...{project, works}} /> : <ProjectArchive {...{project, works, mainSub}} />}
          </ArchiveList>
        </Scrollable>
      </Container>
    </>
  )
}

export const QUERY_MAIN_PROJECT_ARCHIVE_BY_SLUG = gql`
  query ProjectBySlug($id: Int!) {
    parentWorks: allWpWork(filter: {projects: {nodes: {elemMatch: {wpParent: {node: {databaseId: {eq: $id}}}}}}}) {
      edges {
        node {
          ...attachedWork
        }
      }
    }
    childWorks: allWpWork(filter: {projects: {nodes: {elemMatch: {databaseId: {eq: $id}}}}}) {
      edges {
        node {
          ...attachedWork
        }
      }
    }
    project: wpProject(databaseId: {eq: $id}) {
      ...projectFields
      ...mainSub
      children: wpChildren {
        nodes {
          ...projectFields
          ...mainSub
        }
      }
      parent: wpParent {
        node {
          ...projectFields
        }
      }
    }
  }
`
